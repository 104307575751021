import React, { ReactElement, ReactNode } from 'react';
import { DataSchema } from '../../services/Integration';
import { LiquidTemplateInput } from '../../components/LiquidTemplateInput';
import get from 'lodash/get';
import set from 'lodash/set';

export type UiSchemaItem = {
  path: string;
  label?: () => ReactNode;
};

export type UiSchema = Array<UiSchemaItem>;

/**
 * Render a form from a JsonSchema object
 */
export function JsonForm(props: {
  schema: DataSchema;
  value: unknown;
  onChange: (next: unknown) => void;
  disabled: boolean;
  objectKey?: string;
  uiSchema?: UiSchema;
  path?: string[];
}): ReactElement | null {
  const {
    disabled,
    schema,
    value,
    onChange,
    objectKey,
    uiSchema,
    path = [],
  } = props;
  const stateProps = { value, onChange, disabled, objectKey };

  // Ui schema is non-recursive
  if (uiSchema) {
    return (
      <>
        {uiSchema.map(({ path, label }) => {
          const itemSchema = get(schema, path);
          return (
            <StringInput
              key={path}
              schema={itemSchema}
              label={label?.()}
              onChange={(next) =>
                // @TODO Figure out how to properly type the json schema
                set(value as Record<string, unknown>, path, next)
              }
              value={get(value, path, '')}
              disabled={false}
            />
          );
        })}
      </>
    );
  }

  switch (schema.type) {
    case 'object': {
      // @TODO Figure out how to properly type the json schema
      const props = stateProps as {
        value: Record<string, unknown>;
        onChange: (next: Record<string, unknown>) => void;
        disabled: boolean;
      };
      if (!schema.properties) return null;
      return (
        <ObjectForm
          path={path}
          uiSchema={uiSchema}
          properties={schema.properties}
          {...props}
        />
      );
    }

    case 'number':
    case 'integer':
    case 'string':
    case 'boolean':
    case 'array': {
      return <StringInput schema={schema} {...stateProps} />;
    }

    default:
      return <div>{schema.type} not configured</div>;
  }
}

function ObjectForm(props: {
  value: Record<string, unknown>;
  onChange: (next: Record<string, unknown>) => void;
  properties: NonNullable<DataSchema['properties']>;
  disabled: boolean;
  path: string[];
  uiSchema?: UiSchema;
}) {
  const { properties, value, onChange, disabled, path, uiSchema } = props;
  return (
    <>
      {Object.entries(properties)
        .filter(([, schema]) => !schema.readOnly)
        .map(([key, schema]) => {
          const defaultValue = schema.type === 'object' ? {} : '';
          return (
            <JsonForm
              key={key}
              objectKey={key}
              schema={schema}
              value={value[key] ?? defaultValue}
              onChange={(next) => onChange({ ...value, [key]: next })}
              disabled={disabled}
              path={path.concat(key)}
              uiSchema={uiSchema}
            />
          );
        })}
    </>
  );
}

function StringInput({
  schema,
  value,
  onChange,
  disabled,
  objectKey,
  label,
}: {
  schema: DataSchema;
  value: string | unknown;
  onChange: (next: string) => void;
  objectKey?: string;
  disabled: boolean;
  label?: ReactNode;
}) {
  return (
    <div className="flex w-full flex-col gap-2">
      <div className="w-full min-w-32 text-sm font-medium">
        {label ?? schema.title ?? schema.description ?? objectKey}
      </div>
      <LiquidTemplateInput
        workflowId=""
        className="min-h-16"
        value={String(value)}
        onChange={onChange}
        disabled={disabled}
      />
    </div>
  );
}
