import React, { ReactElement, useState } from 'react';
import {
  useWorkflowList,
  useWorkflowRun,
  useWorkflowExecutionsMeeting,
  useWorkflowStopUsing,
} from '../../services/Workflow';
import { Menu } from '../../components/Menu';
import { Button } from '../../components/buttons';
import { FormattedMessage } from 'react-intl';
import {
  ChevronDown,
  CirclePlay,
  ChevronUp,
  X,
  SquareArrowOutUpRight,
  Pencil,
  GitCompare,
  Library,
} from 'lucide-react';
import { WorkflowExecution, WorkflowsType } from '../../graphql/operations';
import { WorkflowDiscoveryModal } from './WorkflowDiscovery';
import {
  workflowItem,
  workflowItemExecution,
  workflowList as workflowListRoute,
} from '../../helpers/routes';
import { WorkflowStatusIcon } from './WorkflowStatusIcon';
import { WorkflowConfirmation } from '../Transcripts/view/WorkflowConfirmation';
import { WorkflowStatus } from '@tactiq/model';
import { BadgeNew } from '../Common/BadgeNew';
import { formatDistanceStrict } from 'date-fns';
import {
  trackMyMeetingClickedWorkflowMenu,
  trackWorkflowLibraryClickedWorkflowMenu,
  trackWorkflowVideoClickedWorkflowMenu,
  trackWorkflowMenuOpened,
  trackWorkflowStarted,
} from '../../helpers/analytics';
import { Skeleton } from '../../components/Skeleton';
import { cx } from '../../helpers/utils';
import { Tooltip } from '../../components/Tooltip';
import { useNavigate } from 'react-router';
import { ConfirmActionDialog } from '../../components/modals';
import { Link } from 'react-router-dom';
import { WorkflowTutorialVideoPlayer } from './VideoTutorialButtons';

export default function WorkflowRunMenu(props: {
  meetingId: string;
}): ReactElement {
  const navigate = useNavigate();
  const { meetingId } = props;
  const [open, setOpen] = useState(false);
  const [videoPlayerOpen, setVideoPlayerOpen] = useState(false);
  const [expanded, setExpanded] = useState(true);
  const [stopUsingId, setStopUsingId] = useState<string | undefined>();
  const usedWorkflows = useWorkflowList({
    input: { type: WorkflowsType.USED_ALL },
  });
  const workflowList = useWorkflowList({
    input: { type: WorkflowsType.MINE },
  });

  const executionData = useWorkflowExecutionsMeeting({ meetingId });
  const workflowRun = useWorkflowRun('meeting');
  const stopUsing = useWorkflowStopUsing();
  const loading = workflowRun.loading;

  const workflows = workflowList.data?.workflows ?? [];

  const used = (usedWorkflows.data?.workflows ?? []).filter((w) => w.isUsed);
  const usedIds = new Set(used.map((ii) => ii.id));

  const executions = executionData.data?.workflowExecutionsMeeting ?? [];

  const fetching =
    usedWorkflows.loading || workflowList.loading || executionData.loading;

  const showEmptyState = workflows.length === 0 && used.length === 0;

  if (fetching) return <Skeleton className="h-24 shrink-0" />;

  let workflowMenuContent = null;

  if (videoPlayerOpen) {
    return (
      <WorkflowTutorialVideoPlayer
        open={videoPlayerOpen}
        onClose={() => setVideoPlayerOpen(false)}
      />
    );
  }

  if (!showEmptyState) {
    workflowMenuContent = (
      <Menu width="100%">
        <Menu.Trigger>
          <Button
            variant="secondaryOutline"
            size="small"
            loading={loading}
            className="flex w-full max-w-full justify-between"
            onClick={() => trackWorkflowMenuOpened({ meetingId })}
          >
            <FormattedMessage defaultMessage="Run workflow" id="7TOSCq" />
            <ChevronDown className="w-5 text-slate-500" />
          </Button>
        </Menu.Trigger>
        {used.length > 0 && (
          <>
            <Menu.Label>
              <FormattedMessage defaultMessage="Recently Used" id="ciiINm" />
            </Menu.Label>
            {used.slice(0, 10).map((w) => (
              <Menu.Item
                key={w.id}
                onClick={() =>
                  workflowRun.request({
                    input: { id: w.id, meetingId },
                  })
                }
              >
                <div className="group relative flex w-full items-center justify-between">
                  <span className="truncate">{w.name}</span>
                  <Tooltip
                    placement="top"
                    arrow
                    title={
                      <FormattedMessage
                        defaultMessage="Stop using"
                        id="RIceT3"
                      />
                    }
                  >
                    <Button
                      disabled={stopUsing.loading}
                      loading={stopUsing.loading}
                      variant="naked"
                      size="icon"
                      className="absolute right-0 hidden group-hover:inline-flex"
                      onClick={(e) => {
                        e.stopPropagation();
                        setStopUsingId(w.id);
                      }}
                      startIcon={<X size="1rem" />}
                    />
                  </Tooltip>
                </div>
              </Menu.Item>
            ))}
            <Menu.Divider />
          </>
        )}
        <Menu.Label>
          <FormattedMessage defaultMessage="My workflows" id="KjL2+I" />
        </Menu.Label>
        {workflows
          .filter((w) => !usedIds.has(w.id))
          .slice(0, 10)
          .map((w) => (
            <Menu.Item
              key={w.id}
              onClick={() => {
                trackWorkflowStarted({
                  workflowId: w.id,
                  meetingId,
                  source: 'meeting',
                });

                workflowRun.request({ input: { id: w.id, meetingId } });
              }}
            >
              <div className="group relative flex w-full items-center justify-between">
                <span className="truncate">{w.name}</span>
                <Tooltip
                  placement="top"
                  arrow
                  title={
                    <FormattedMessage
                      defaultMessage="Edit workflow"
                      id="1Ufa47"
                    />
                  }
                >
                  <Button
                    variant="naked"
                    size="icon"
                    className="absolute right-0 hidden group-hover:inline-flex"
                    onClick={(e) => {
                      e.stopPropagation();
                      navigate(workflowItem.replace(':id', w.id));
                    }}
                    startIcon={<Pencil size="1rem" />}
                  />
                </Tooltip>
              </div>
            </Menu.Item>
          ))}
        <Menu.Divider />
        <Menu.Item
          icon={<GitCompare className="size-4 stroke-slate-700" />}
          as="link"
          to={workflowListRoute}
          onClick={() => trackMyMeetingClickedWorkflowMenu({ meetingId })}
        >
          <FormattedMessage defaultMessage="My workflows" id="KjL2+I" />
        </Menu.Item>
        <Menu.Item
          icon={<Library className="size-4 stroke-slate-700" />}
          onClick={() => {
            trackWorkflowLibraryClickedWorkflowMenu({ meetingId });
            setOpen(true);
          }}
        >
          <FormattedMessage defaultMessage="Workflow Library" id="mDVer5" />
        </Menu.Item>
        <Menu.Item
          icon={<CirclePlay className="size-4 stroke-slate-700" />}
          onClick={() => {
            trackWorkflowVideoClickedWorkflowMenu({ meetingId });
            setVideoPlayerOpen(true);
          }}
        >
          <FormattedMessage defaultMessage="Watch video" id="wdsniY" />
        </Menu.Item>
      </Menu>
    );
  }

  return (
    <>
      <WorkflowDiscoveryModal
        meetingId={meetingId}
        open={open}
        setOpen={setOpen}
      />
      {stopUsingId && (
        <ConfirmActionDialog
          open
          title={
            <FormattedMessage
              defaultMessage="Stop using workflow"
              id="N9eLq8"
            />
          }
          text={
            <FormattedMessage
              defaultMessage="Are you sure you want to stop using this workflow?"
              id="549m56"
            />
          }
          yes={<FormattedMessage defaultMessage="Yes" id="a5msuh" />}
          yesProps={{ loading: stopUsing.loading, color: 'error' }}
          onYes={async () => {
            await stopUsing.request({
              input: { id: stopUsingId },
            });
            setStopUsingId(undefined);
          }}
          onNo={() => setStopUsingId(undefined)}
        />
      )}
      <div className="rounded-2xl bg-gradient-to-b from-brand-400 to-pink-400 p-[3px]">
        <div className="flex h-full w-full flex-col gap-3 rounded-[13px] border border-slate-200/70 bg-white p-4 text-slate-600">
          <div className="flex items-center gap-2 text-sm font-medium text-slate-700">
            <GitCompare className="size-7 rounded-lg bg-slate-100 p-1.5 text-slate-600" />

            {showEmptyState ? (
              <Button
                variant="naked"
                size="small"
                onClick={() => {
                  trackWorkflowLibraryClickedWorkflowMenu({ meetingId });
                  setOpen(true);
                }}
                endIcon={<SquareArrowOutUpRight className="w-5" />}
              >
                <FormattedMessage
                  defaultMessage="Discover workflows"
                  id="X/VvPv"
                />
              </Button>
            ) : (
              <>
                <FormattedMessage defaultMessage="Workflows" id="6La3aS" />
                <BadgeNew visible />
              </>
            )}
          </div>
          {workflowMenuContent}
          {executions.length > 0 && (
            <>
              <div className="flex items-center gap-1">
                <Button
                  variant="naked"
                  size="small"
                  onClick={() => setExpanded(!expanded)}
                  className="!text-slate-600"
                  endIcon={
                    expanded ? (
                      <ChevronUp size="1rem" className="" />
                    ) : (
                      <ChevronDown size="1rem" className="" />
                    )
                  }
                >
                  <FormattedMessage
                    defaultMessage="Recent activity"
                    id="YsScAY"
                  />
                </Button>
              </div>
              {expanded && (
                <div className="flex flex-col gap-y-1">
                  {executions.map((ex) => {
                    return (
                      <ExecutionItem
                        key={ex.id}
                        execution={ex}
                        meetingId={meetingId}
                      />
                    );
                  })}
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
}

const ExecutionItem = ({
  execution,
}: {
  execution: WorkflowExecution;
  meetingId: string;
}) => {
  const classes =
    'group rounded-lg bg-slate-50 hover:bg-slate-100 transition-all duration-1000 overflow-hidden p-3';
  const title = (
    <div
      key={execution.id}
      className={cx(
        'relative flex w-full cursor-pointer items-center gap-1 rounded-md transition-all duration-1000'
      )}
    >
      <div className="flex-shrink-0">
        <WorkflowStatusIcon status={execution.status} />
      </div>
      <div className="mr-auto flex-grow truncate pr-4 text-sm">
        {execution.workflowName}
      </div>

      <div className="absolute right-0 hidden items-center gap-1 truncate py-2.5 pl-3 pr-2 text-xs text-slate-500 group-hover:flex">
        <span>
          {formatDistanceStrict(new Date(execution.updatedAt), new Date(), {
            addSuffix: true,
          })}
        </span>
      </div>
    </div>
  );

  if (execution.status === WorkflowStatus.WAITING_FOR_CONFIRMATION) {
    return (
      <div className={classes}>
        {title}
        <div className="mt-3 flex flex-col gap-2">
          <WorkflowConfirmation execution={execution} />
        </div>
      </div>
    );
  }

  return (
    <Link
      className={classes}
      to={workflowItemExecution
        .replace(':id', execution.workflowId)
        .replace(':executionId', execution.id)}
    >
      {title}
    </Link>
  );
};
