import { useMutation } from '@apollo/client';
import { Locale, getPreferredLocale } from '@tactiq/i18n';
import noop from 'lodash/noop';
import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { EditableText } from '../../../components/TextInput/EditableText';
import { Button } from '../../../components/buttons';
import { Select } from '../../../components/Select';
import { Switch } from '../../../components/buttons/Switch';
import {
  SetTimestampOptionDocument,
  UpdateUserSettingDocument,
  UserSettingsAutosaveTimestampOption,
  UserSettingsNotificationsMeetingsChat,
  UserSettingsNotificationsTranscriptEmails,
  UserSettingsNotificationsTranscriptExtension,
  UserSettingsSharingParticipants,
} from '../../../graphql/operations';
import {
  TierPricingDialogSource,
  trackUpdatePreferredLanguage,
  trackWebEvent,
  trackWebPage,
} from '../../../helpers/analytics';
import { kSettingsAccount } from '../../../helpers/routes';
import {
  selectAddableSpaces,
  selectIsProUser,
  selectTeam,
  selectUserEmail,
  selectUserName,
  selectUserSettings,
  selectUserTier,
} from '../../../redux/selectors';
import { RootState } from '../../../redux/store';
import { SettingsRow } from '../../Common/SettingsRow';
import { TierPricingDialog } from '../../Credits/TierPricing/TierPricingDialog';
import { isTeamShareAllowed } from '@tactiq/model';
import { Link } from '../../../components/Link';
import { AiOutputLanguageSelector } from '../../Common/AiOutputLanguageSelector';
import { useUpdateUserDisplayName } from '../../../services/User';

const languages = [
  {
    id: Locale.en,
    title: 'English',
  },
  {
    id: Locale.es,
    title: 'Español',
  },
  {
    id: Locale.pt,
    title: 'Português',
  },
  {
    id: Locale.fr,
    title: 'Français',
  },
  {
    id: Locale.de,
    title: 'Deutsch',
  },
  {
    id: Locale.it,
    title: 'Italiano',
  },
  {
    id: Locale.ja,
    title: '日本語',
  },
];

const meetingDateFormats = [
  'dd-mm-yyyy',
  'dd/mm/yyyy',
  'dd.mm.yyyy',
  'mm/dd/yyyy',
  'mm-dd-yyyy',
  'yyyy-mm-dd',
];

const defaultColor = 'rgba(254, 255, 2, 0.8)';
const SPACE_SHARING_DISABLED = 'DISABLED';

export const GeneralSettings: React.FC = () => {
  const [needsTeamUpgrade, setNeedsTeamUpgrade] =
    React.useState<boolean>(false);
  const [showTierPricingDialog, setShowTierPricingDialog] =
    React.useState<boolean>(false);
  const userTier = useSelector(selectUserTier);
  const userDisplayName = useSelector(selectUserName);
  const userEmail = useSelector(selectUserEmail);
  const userSettings = useSelector(selectUserSettings);
  const hasGoogleCalendar = useSelector(
    (state: RootState) => state.user.hasGoogleCalendar
  );
  const team = useSelector(selectTeam);
  const isPaidUser = useSelector(selectIsProUser);
  const navigate = useNavigate();
  const intl = useIntl();

  // Spaces in which user has permission to add a meeting
  const addableSpaces = useSelector(selectAddableSpaces);
  const addableSpacesSorted = [...addableSpaces].sort((a, b) =>
    a.name.localeCompare(b.name)
  );

  useEffect(() => {
    trackWebPage('Settings');
  }, []);

  const [setTimestampOption, setTimestampOptionQuery] = useMutation(
    SetTimestampOptionDocument
  );

  const [updateUserSetting, updateUserSettingMutation] = useMutation(
    UpdateUserSettingDocument
  );

  const {
    request: updateDisplayNameRequest,
    loading: updateDisplayNameLoading,
  } = useUpdateUserDisplayName();

  const onSaveDisplayName = async (input: string) => {
    if (input.trim().length === 0) {
      return;
    }
    !updateDisplayNameLoading &&
      (await updateDisplayNameRequest({
        input: { displayName: input },
      }));
  };

  function checkLanguageLocale(lang: string): Locale {
    return (
      Object.values(Locale).find((l) => l === lang) ??
      getPreferredLocale(userSettings.lang)
    );
  }

  return (
    <div>
      <ul role="list" className="flex flex-col divide-y divide-slate-100">
        <SettingsRow
          settingInfo={{
            title: intl.formatMessage({
              defaultMessage: 'Change display name',
              id: 'g2BWbe',
              description: 'Setting description',
            }),
            description: intl.formatMessage({
              defaultMessage: 'Change your name as it appears in Tactiq',
              id: 'XII2Jj',
              description: 'Setting description',
            }),
          }}
          settingAction={
            <EditableText
              value={userDisplayName}
              onSave={onSaveDisplayName}
              isValid={(s) => Boolean(s.trim())}
              errorLabel={intl.formatMessage({
                defaultMessage: 'Display name can not be empty',
                id: '6SOsH2',
                description: 'Setting description',
              })}
            />
          }
        />

        {isTeamShareAllowed(team?.id) && (
          <SettingsRow
            settingInfo={{
              title: intl.formatMessage({
                defaultMessage: 'Automatically add all new meetings to a space',
                id: 'egO30Q',
                description: 'Setting description',
              }),
              description: (
                <FormattedMessage
                  defaultMessage="This will add transcripts to the selected space in Tactiq"
                  id="ttG8JQ"
                />
              ),
            }}
            requiresUpgrade={{
              condition: team?.plan?.status !== 'active',
              reason: 'Needs team plan',
              prompt: (
                <FormattedMessage
                  defaultMessage="A team plan is required to use this feature."
                  id="39yOVF"
                />
              ),
              action: () => {
                setNeedsTeamUpgrade(true);
                setShowTierPricingDialog(true);
              },
            }}
            settingAction={
              <Select
                value={userSettings.sharing?.space ?? SPACE_SHARING_DISABLED}
                disabled={updateUserSettingMutation.loading}
                onChange={async (value) => {
                  trackWebEvent('Settings - Toggled space sharing', {
                    value,
                    isPaidUser,
                  });
                  await updateUserSetting({
                    variables: {
                      input: {
                        sharing: {
                          space: (value === SPACE_SHARING_DISABLED
                            ? // Setting the value as 'undefined' instead of 'null' will cause the Apollo client to omit
                              // this field from the payload. This results in a payload that resembles `{ sharing: {} }`.
                              // Consequently, disabling space sharing will also reset all other sharing options.
                              // This behavior is the rationale behind the type casting in the subsequent line.
                              null
                            : value) as string,
                        },
                      },
                    },
                  });
                }}
                options={[
                  {
                    value: SPACE_SHARING_DISABLED,
                    label: intl.formatMessage({
                      defaultMessage: 'Disabled',
                      id: 'pDtzLx',
                      description: 'User settings. Transcript space sharing.',
                    }),
                  },
                  ...addableSpacesSorted.map((space) => ({
                    value: space.id,
                    label: space.name,
                    icon: space.icon,
                  })),
                ]}
              />
            }
          />
        )}

        <SettingsRow
          settingInfo={{
            title: intl.formatMessage({
              defaultMessage:
                'Share all your future transcripts with meeting participants',
              id: '4rl6r0',
              description: 'Setting description',
            }),
            description: (
              <FormattedMessage
                defaultMessage="This will share the transcript with meeting participants in your Google calendar meeting"
                id="F4tqkb"
              />
            ),
          }}
          settingAction={
            <Select
              value={
                userSettings.sharing?.participants ??
                UserSettingsSharingParticipants.DISABLED
              }
              disabled={updateUserSettingMutation.loading}
              onChange={async (participants) => {
                trackWebEvent('Settings - Toggled participants sharing', {
                  participants,
                });

                await updateUserSetting({
                  variables: { input: { sharing: { participants } } },
                });
              }}
              options={[
                {
                  value: UserSettingsSharingParticipants.AUTO,
                  label: intl.formatMessage({
                    defaultMessage: 'Share automatically',
                    id: 'b5NDVE',
                    description:
                      'User settings. Transcript participants sharing.',
                  }),
                },
                {
                  value: UserSettingsSharingParticipants.DISABLED,
                  label: intl.formatMessage({
                    defaultMessage: 'Disabled',
                    id: '871tpq',
                    description:
                      'User settings. Transcript participants sharing.',
                  }),
                },
              ]}
            />
          }
          requiresUpgrade={{
            condition: !hasGoogleCalendar,
            reason: 'Needs google calendar',
            prompt: (
              <FormattedMessage
                defaultMessage="Connected Google Calendar integration is required to use this feature."
                id="8wn/cM"
                description="User settings. Google Calendar only setting."
              />
            ),
            action: () => {
              trackWebEvent(
                'Settings - Google Calendar integration chip clicked'
              );
              navigate('/integrations/google-calendar');
            },
          }}
        />

        <SettingsRow
          settingInfo={{
            title: intl.formatMessage({
              defaultMessage: 'Enable AI features (Large Language Models)',
              id: 'AEENT9',
              description: 'Setting description',
            }),
            description: (
              <FormattedMessage
                defaultMessage="This gives you access to AI-generated summaries, Meeting Kits, and more. We use OpenAI GPT API for generation. They do not store your data and do not use it to improve their models. Read more at {link}"
                id="2zsdjQ"
                description="Setting description"
                values={{
                  link: (
                    <Link target="_blank" blue to="https://tactiq.io/gpt">
                      https://tactiq.io/gpt
                    </Link>
                  ),
                }}
              />
            ),
          }}
          overriddenByTeam={team?.settings.enableLLM === false}
          settingAction={
            <Switch
              disabled={
                team?.settings.enableLLM === false ||
                updateUserSettingMutation.loading
              }
              onClick={async () => {
                trackWebEvent('Settings - Toggled LLMs', {
                  enabled: !userSettings.enableLLM,
                });
                await updateUserSetting({
                  variables: {
                    input: {
                      enableLLM: !userSettings.enableLLM,
                    },
                  },
                });
              }}
              isOn={
                team?.settings.enableLLM === false
                  ? false
                  : (userSettings.enableLLM ?? true)
              }
            />
          }
        />

        <SettingsRow
          settingInfo={{
            title: intl.formatMessage({
              defaultMessage: 'AI-generated content language',
              id: '/o0HC6',
              description: 'Setting description',
            }),
            description: (
              <FormattedMessage
                defaultMessage="The default language for AI-generated output can be set to match meeting's language or a language of your choice. You can also set this for each meeting individually on meeting's transcript page."
                id="ersnm3"
                description="Setting description"
              />
            ),
          }}
          settingAction={
            <AiOutputLanguageSelector
              disabled={updateUserSettingMutation.loading}
              value={userSettings.aiOutputLanguage}
              onChange={async (value) => {
                trackWebEvent('Settings - Changed AI output language', {
                  lang: value,
                });
                await updateUserSetting({
                  variables: {
                    input: {
                      aiOutputLanguage: value,
                    },
                  },
                });
              }}
              context="settings"
            />
          }
        />

        <SettingsRow
          settingInfo={{
            title: intl.formatMessage({
              defaultMessage: 'Transcription Notification',
              id: 'gGHSN2',
              description: 'Setting description',
            }),
            description: (
              <FormattedMessage
                defaultMessage="Enable this setting to notify meeting participants about Tactiq's transcription feature automatically. When activated, a message will be sent in the chat to inform others that the meeting is being transcribed."
                id="kAWP4l"
                description="Setting description"
              />
            ),
            helpLink: {
              condition: !UserSettingsNotificationsMeetingsChat.DISABLED,
              message: (
                <FormattedMessage
                  defaultMessage="You can read more about this setting here."
                  id="h7k46Y"
                />
              ),
              url: 'https://help.tactiq.io/en/articles/5446775-notify-meeting-attendees-you-re-transcribing-meeting-with-tactiq',
            },
          }}
          requiresUpgrade={{
            condition: !isPaidUser,
            reason: 'Needs paid plan',
            prompt: (
              <FormattedMessage
                defaultMessage="You need to be on a Pro plan or higher to use this feature."
                id="7tKNrs"
              />
            ),
            action: () => setShowTierPricingDialog(true),
          }}
          settingAction={
            <Select
              value={
                userSettings.notifications?.meetings?.chat ||
                UserSettingsNotificationsMeetingsChat.DISABLED
              }
              disabled={updateUserSettingMutation.loading}
              onChange={async (chat) => {
                trackWebEvent(
                  'Settings - Toggled in-meeting chat notifications',
                  { value: chat }
                );

                await updateUserSetting({
                  variables: {
                    input: {
                      notifications: {
                        transcripts: {
                          emails:
                            userSettings.notifications?.transcripts?.emails ??
                            UserSettingsNotificationsTranscriptEmails.EACH,
                          extension:
                            userSettings.notifications?.transcripts
                              ?.extension ??
                            UserSettingsNotificationsTranscriptExtension.WHEN_MEETING_ENDED,
                        },
                        meetings: { chat },
                      },
                    },
                  },
                });
              }}
              options={[
                {
                  value: UserSettingsNotificationsMeetingsChat.UNDECIDED,
                  label: intl.formatMessage({
                    defaultMessage: 'Default prompt',
                    id: 'ou74xL',
                    description: 'The default prompt to help you decide.',
                  }),
                },
                {
                  value: UserSettingsNotificationsMeetingsChat.PROMPT,
                  label: intl.formatMessage({
                    defaultMessage: 'Always prompt',
                    id: 'ui2xhX',
                    description: 'User settings. Meeting chat notifications.',
                  }),
                },
                {
                  value:
                    UserSettingsNotificationsMeetingsChat.AUTO_TRANSCRIBING,
                  label: intl.formatMessage({
                    defaultMessage:
                      'Always notify that the transcription is active',
                    id: 'WqqGlH',
                    description: 'User settings. Meeting chat notifications.',
                  }),
                },
                {
                  value: UserSettingsNotificationsMeetingsChat.AUTO_SHARE,
                  label: intl.formatMessage({
                    defaultMessage:
                      'Always notify with the link to the transcript',
                    id: 'GSnuDj',
                    description: 'User settings. Meeting chat notifications.',
                  }),
                },
                {
                  value: UserSettingsNotificationsMeetingsChat.DISABLED,
                  label: intl.formatMessage({
                    defaultMessage: 'Disabled',
                    id: 'YzS/ag',
                    description: 'User settings. Meeting chat notifications.',
                  }),
                },
              ]}
            />
          }
        />

        <SettingsRow
          settingInfo={{
            title: intl.formatMessage({
              defaultMessage: 'Auto-pause',
              id: 'G9mXN3',
              description: 'Setting description',
            }),
            description: (
              <FormattedMessage
                defaultMessage="Pause transcription at the start of each meeting"
                id="e316PB"
                description="Setting description"
              />
            ),
            helpLink: {
              condition: userSettings.autoPauseEnabled,
              message: (
                <FormattedMessage
                  defaultMessage="You could be missing meeting transcripts if you forget to un-pause transcripts manually."
                  id="9rck2L"
                />
              ),
              url: 'https://help.tactiq.io/en/articles/5570498-pause-and-auto-pause',
            },
          }}
          settingAction={
            <Switch
              disabled={updateUserSettingMutation.loading}
              onClick={async () => {
                trackWebEvent('Settings - Toggled auto pause', {
                  enabled: !userSettings.autoPauseEnabled,
                });
                await updateUserSetting({
                  variables: {
                    input: {
                      autoPauseEnabled: !userSettings.autoPauseEnabled,
                    },
                  },
                });
              }}
              isOn={userSettings.autoPauseEnabled ?? false}
            />
          }
          requiresUpgrade={{
            condition: !isPaidUser,
            reason: 'Needs paid plan',
            prompt: (
              <FormattedMessage
                defaultMessage="You need to be on a Pro plan or higher to use this feature."
                id="7tKNrs"
              />
            ),
            action: () => setShowTierPricingDialog(true),
          }}
        />

        <SettingsRow
          settingInfo={{
            title: intl.formatMessage({
              defaultMessage: 'Save chat along with the transcript',
              id: 'DyKYT6',
              description: 'Setting description',
            }),
          }}
          settingAction={
            <Switch
              disabled={updateUserSettingMutation.loading}
              onClick={async () => {
                trackWebEvent('Settings - Toggled chat capture', {
                  enabled: !userSettings.ignoreChat,
                });
                await updateUserSetting({
                  variables: {
                    input: {
                      ignoreChat: !userSettings.ignoreChat,
                    },
                  },
                });
              }}
              isOn={!userSettings.ignoreChat}
            />
          }
        />

        <SettingsRow
          settingInfo={{
            title: intl.formatMessage({
              defaultMessage: 'Configure time display in the transcript',
              id: 'iqBgh/',
              description: 'Setting description',
            }),
          }}
          settingAction={
            <Select
              value={userSettings.autoSaveOptions.timestampOption}
              disabled={setTimestampOptionQuery.loading}
              onChange={async (timestampOption) => {
                trackWebEvent('Settings - Changed timestamp option', {
                  option: timestampOption,
                });
                await setTimestampOption({
                  variables: { input: { timestampOption } },
                });
              }}
              options={[
                {
                  value: UserSettingsAutosaveTimestampOption.DURATION,
                  label: intl.formatMessage({
                    defaultMessage: 'Time since the meeting start',
                    id: 'KSRINT',
                    description: 'Setting option text',
                  }),
                },
                {
                  value: UserSettingsAutosaveTimestampOption.UTC,
                  label: intl.formatMessage({
                    defaultMessage: 'Exact time',
                    id: '0c7SkP',
                    description: 'Setting option text',
                  }),
                },
                {
                  value: UserSettingsAutosaveTimestampOption.NONE,
                  label: intl.formatMessage({
                    defaultMessage: 'No timestamps',
                    id: '4uaZ+n',
                    description: 'Setting option text',
                  }),
                },
              ]}
            />
          }
        />

        <SettingsRow
          settingInfo={{
            title: intl.formatMessage({
              defaultMessage: 'Customise highlight color',
              id: '3vEZ03',
              description: 'Setting description',
            }),
          }}
          settingAction={
            <Select
              value={userSettings.highlightColor ?? defaultColor}
              disabled={updateUserSettingMutation.loading}
              onChange={async (highlightColor) => {
                trackWebEvent('Settings - Changed highlight color', {
                  color: highlightColor,
                });
                await updateUserSetting({
                  variables: {
                    input: { highlightColor },
                  },
                });
              }}
              options={[
                {
                  value: defaultColor,
                  label: intl.formatMessage({
                    defaultMessage: 'Marker Pen',
                    id: '7clJ2o',
                    description: 'Highlight color name',
                  }),
                },
                {
                  value: 'rgba(255, 140, 0, 0.9)',
                  label: intl.formatMessage({
                    defaultMessage: 'Bright Orange',
                    id: '4vWhoT',
                    description: 'Highlight color name',
                  }),
                },
                {
                  value: 'rgb(67, 181, 129)',
                  label: intl.formatMessage({
                    defaultMessage: 'Tactiq Green',
                    id: 'OLUMUE',
                    description: 'Highlight color name',
                  }),
                },
                {
                  value: 'rgb(66, 133, 244)',
                  label: intl.formatMessage({
                    defaultMessage: 'Google Blue',
                    id: 'lf91UY',
                    description: 'Highlight color name',
                  }),
                },
              ].map((x) => {
                return {
                  ...x,
                  icon: (
                    <div
                      className="size-3 rounded-sm"
                      style={{ backgroundColor: x.value }}
                    />
                  ),
                };
              })}
            />
          }
        />

        <SettingsRow
          settingInfo={{
            title: intl.formatMessage({
              defaultMessage:
                'Notify me when something is automatically highlighted',
              id: '498caK',
              description: 'Setting description',
            }),
          }}
          settingAction={
            <Switch
              disabled={updateUserSettingMutation.loading}
              onClick={async () => {
                trackWebEvent('Settings - Toggled autohighlight notification', {
                  enabled: !userSettings.hideAutoHighlightSnacks,
                });
                await updateUserSetting({
                  variables: {
                    input: {
                      hideAutoHighlightSnacks:
                        userSettings.hideAutoHighlightSnacks !== true,
                    },
                  },
                });
              }}
              isOn={userSettings.hideAutoHighlightSnacks === false}
            />
          }
        />

        <SettingsRow
          settingInfo={{
            title: intl.formatMessage({
              defaultMessage: 'Configure meeting title format',
              id: 'Y2KbTH',
              description: 'Setting description',
            }),
          }}
          settingAction={
            <Select
              value={userSettings.meetingTitleFormat ?? 't'}
              disabled={updateUserSettingMutation.loading}
              onChange={async (meetingTitleFormat) => {
                trackWebEvent('Settings - Changed meeting title format', {
                  option: meetingTitleFormat,
                });
                await updateUserSetting({
                  variables: {
                    input: { meetingTitleFormat },
                  },
                });
              }}
              options={[
                {
                  value: 'td',
                  label: intl.formatMessage({
                    defaultMessage: 'Title Date',
                    id: 'xHUApf',
                  }),
                },
                {
                  value: 'dt',
                  label: intl.formatMessage({
                    defaultMessage: 'Date Title',
                    id: 'nfzg8c',
                  }),
                },
                {
                  value: 't',
                  label: intl.formatMessage({
                    defaultMessage: 'Title',
                    id: '9a9+ww',
                  }),
                },
              ]}
            />
          }
        />

        <SettingsRow
          settingInfo={{
            title: intl.formatMessage({
              defaultMessage: "Configure meeting title's date format",
              id: 'S5hqyU',
              description: 'Setting description',
            }),
          }}
          settingAction={
            <Select
              value={userSettings.meetingTitleDateFormat ?? 'dd-mm-yyyy'}
              disabled={updateUserSettingMutation.loading}
              onChange={async (meetingTitleDateFormat) => {
                trackWebEvent('Settings - Changed meeting title date format', {
                  option: meetingTitleDateFormat,
                });
                await updateUserSetting({
                  variables: {
                    input: { meetingTitleDateFormat },
                  },
                });
              }}
              options={meetingDateFormats.map((value) => ({
                value,
                label: value,
              }))}
            />
          }
        />

        <SettingsRow
          settingInfo={{
            title: intl.formatMessage({
              defaultMessage: 'Preferred language',
              id: '/Wm1Ag',
              description: 'Setting description',
            }),
            description: (
              <FormattedMessage
                defaultMessage="We will use this language preference to configure the UI in Tactiq Chrome extension and web app."
                id="zcYB2X"
                description="Preferred language setting description"
              />
            ),
            helpLink: {
              message: (
                <FormattedMessage
                  defaultMessage="Looking to change the transcript language in your meetings?"
                  id="WcF99n"
                />
              ),
              url: 'https://help.tactiq.io/en/articles/4786785-change-the-language-of-transcription',
            },
          }}
          settingAction={
            <Select
              value={getPreferredLocale(userSettings.lang)}
              disabled={updateUserSettingMutation.loading}
              onChange={async (value) => {
                const lang = checkLanguageLocale(value);
                trackUpdatePreferredLanguage(lang, 'settingsPage');
                await updateUserSetting({ variables: { input: { lang } } });
              }}
              options={languages.map(({ id, title }) => ({
                value: id,
                label: title,
              }))}
            />
          }
        />

        <SettingsRow
          settingInfo={{
            title: intl.formatMessage({
              defaultMessage: 'Transcription Language',
              id: 'DpMQiE',
              description: 'Setting description',
            }),
          }}
          settingAction={
            <Button
              href="https://help.tactiq.io/en/articles/4786785-change-the-language-of-transcription"
              target="_blank"
            >
              <FormattedMessage defaultMessage="Find out more" id="1eP3ff" />
            </Button>
          }
        />

        <SettingsRow
          settingInfo={{
            title: intl.formatMessage({
              defaultMessage: 'Transcript email notifications',
              id: 'W2fiyR',
              description: 'Setting description',
            }),
          }}
          settingAction={
            <Select
              value={
                userSettings.notifications?.transcripts?.emails ||
                UserSettingsNotificationsTranscriptEmails.EACH
              }
              disabled={updateUserSettingMutation.loading}
              onChange={async (emails) => {
                trackWebEvent(
                  'Settings - Toggled single transcript notifications',
                  { value: emails }
                );

                await updateUserSetting({
                  variables: {
                    input: {
                      notifications: {
                        transcripts: {
                          emails,
                          extension:
                            userSettings.notifications?.transcripts
                              ?.extension ??
                            UserSettingsNotificationsTranscriptExtension.WHEN_MEETING_ENDED,
                        },
                        meetings: {
                          chat:
                            userSettings.notifications?.meetings?.chat ??
                            UserSettingsNotificationsMeetingsChat.DISABLED,
                        },
                      },
                    },
                  },
                });
              }}
              options={[
                {
                  value: UserSettingsNotificationsTranscriptEmails.EACH,
                  label: intl.formatMessage({
                    defaultMessage: 'Send for each meeting',
                    id: 'Vzm2IM',
                    description:
                      'User settings. Transcript email notifications.',
                  }),
                },
                {
                  value: UserSettingsNotificationsTranscriptEmails.DISABLED,
                  label: intl.formatMessage({
                    defaultMessage: 'Disabled',
                    id: '6GdceG',
                    description:
                      'User settings. Transcript email notifications.',
                  }),
                },
              ]}
            />
          }
        />

        <SettingsRow
          settingInfo={{
            title: intl.formatMessage({
              defaultMessage: 'Open transcript after the meeting',
              id: 'UFTgAu',
              description: 'Setting description',
            }),
          }}
          settingAction={
            <Select
              value={
                userSettings.notifications?.transcripts?.extension ||
                UserSettingsNotificationsTranscriptExtension.DISABLED
              }
              disabled={updateUserSettingMutation.loading}
              onChange={async (extension) => {
                trackWebEvent(
                  'Settings - Toggled open transcript after the meeting',
                  {
                    value: extension,
                  }
                );

                await updateUserSetting({
                  variables: {
                    input: {
                      notifications: {
                        transcripts: {
                          emails:
                            userSettings.notifications?.transcripts?.emails ??
                            UserSettingsNotificationsTranscriptEmails.EACH,
                          extension,
                        },
                        meetings: {
                          chat:
                            userSettings.notifications?.meetings?.chat ??
                            UserSettingsNotificationsMeetingsChat.DISABLED,
                        },
                      },
                    },
                  },
                });
              }}
              options={[
                {
                  value:
                    UserSettingsNotificationsTranscriptExtension.WHEN_MEETING_ENDED,
                  label: intl.formatMessage({
                    defaultMessage: 'Open transcript when the meeting ends',
                    id: 'C0P++m',
                    description:
                      'User settings. Transcript extension notification.',
                  }),
                },
                {
                  value: UserSettingsNotificationsTranscriptExtension.DISABLED,
                  label: intl.formatMessage({
                    defaultMessage: 'Disabled',
                    id: '1XOPFE',
                    description:
                      'User settings. Transcript extension notifications.',
                  }),
                },
              ]}
            />
          }
        />

        {userEmail?.endsWith('@cloudfactory.com') && (
          <SettingsRow
            settingInfo={{
              title: intl.formatMessage({
                defaultMessage: 'Storage',
                id: 'DUIDEq',
              }),
              description: (
                <FormattedMessage
                  defaultMessage="
                  This setting is enforced for your team."
                  id="sbtyXG"
                />
              ),
            }}
            settingAction={
              <Select
                value="googledrive"
                disabled
                onChange={() => {}}
                options={[{ value: 'googledrive', label: 'Google Drive' }]}
              />
            }
          />
        )}

        {userEmail?.endsWith('@cloudfactory.com') && (
          <SettingsRow
            settingInfo={{
              title: intl.formatMessage({
                defaultMessage: 'Single Sign-On',
                id: '8M4Ich',
              }),
              description: (
                <FormattedMessage
                  defaultMessage="This setting is enforced for your team."
                  id="5QPuSX"
                />
              ),
            }}
            settingAction={<Switch onClick={noop} isOn={true} />}
          />
        )}

        {Boolean(userSettings.privacy?.localFields.length) && (
          <SettingsRow
            settingInfo={{
              title: intl.formatMessage({
                defaultMessage: 'Enhanced privacy',
                id: 'OqSPAM',
              }),
              description: (
                <FormattedMessage
                  defaultMessage="
                  Meeting titles and participants are not stored in Tactiq. This setting is enforced for your team.
                  "
                  id="N6xxeY"
                />
              ),
            }}
            settingAction={<Switch onClick={noop} isOn={true} />}
          />
        )}

        <SettingsRow
          settingInfo={{
            title: intl.formatMessage({
              defaultMessage: 'Account Actions',
              id: '4FyIts',
            }),
          }}
          settingAction={
            <Button href={`/#${kSettingsAccount}`}>
              <FormattedMessage
                defaultMessage="View account settings"
                id="m7B/FR"
                description="View account settings button label"
              />
            </Button>
          }
        />
      </ul>
      {showTierPricingDialog && (
        <TierPricingDialog
          userTier={userTier}
          teamTier={team?.tier}
          source={TierPricingDialogSource.SETTINGS}
          onClose={() => {
            setNeedsTeamUpgrade(false);
            setShowTierPricingDialog(false);
          }}
          teamSpecific={needsTeamUpgrade}
        />
      )}
    </div>
  );
};
