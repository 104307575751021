import React, { ReactElement, useEffect } from 'react';
import { ReactFlow, Controls, Background, MarkerType } from '@xyflow/react';
// The docs say that you need this for functionality - i'm not sure why though
import '@xyflow/react/dist/style.css';

import {
  useWorkflowExecution,
  useWorkflowExecutionList,
  workflowDuration,
} from '../../services/Workflow';
import { Spinner } from '../../components/Spinner';
import { workflowItemActivity } from '../../helpers/routes';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { FormattedDate, FormattedTime } from 'react-intl';
import { cx } from '../../helpers/utils';
import { WorkflowStatusIcon } from './WorkflowStatusIcon';
import ExecutionEdge, { ExecutionEdgeMarkerDefs } from './edges/ExecutionEdge';
import { nodeTypes } from './nodes';
import { viewWorkflowHistoryPage } from '../../helpers/analytics';

type Props = {
  workflowId: string;
  nodeDragThreshold: number;
};

export default function WorkflowActivity(props: Props): ReactElement {
  const { workflowId } = props;
  const { data, loading, error } = useWorkflowExecutionList({
    workflowId: props.workflowId,
  });

  useEffect(() => {
    viewWorkflowHistoryPage({ workflowId });
  }, []);

  const navigate = useNavigate();
  const params = useParams<{ executionId: string }>();
  const live = useWorkflowExecution({ id: params.executionId }); // Subscribe to changes
  const firstExecutionId = data?.workflowExecutions[0]?.id;
  const activityPath = workflowItemActivity.replace(':id', workflowId);
  const ex =
    live.data?.workflowExecution ??
    data?.workflowExecutions.find((e) => e.id === params.executionId);

  const nodeData = Object.fromEntries(ex?.nodeData.map((n) => [n.id, n]) ?? []);

  const nodes =
    ex?.definition.nodes.map((node) => ({
      ...node,
      data: {
        ...node.data,
        execution: nodeData[node.id],
      },
    })) ?? [];

  const edges =
    ex?.definition.edges.map((e) => ({
      ...e,
      type: 'ExecutionEdge',
      markerEnd: { type: MarkerType.ArrowClosed },
    })) ?? [];

  useEffect(() => {
    if (!params.executionId && firstExecutionId) {
      navigate(`${activityPath}/${firstExecutionId}`, { replace: true });
    }
  }, [navigate, activityPath, params.executionId, firstExecutionId]);

  if (error) throw error;
  if (loading || !nodes || !edges) {
    return (
      <div className="flex h-full w-full flex-row items-center justify-center">
        <Spinner size="1.5rem" />
      </div>
    );
  }

  return (
    <div className="relative flex h-full w-full flex-grow overflow-auto bg-slate-25">
      <ExecutionEdgeMarkerDefs />
      <ReactFlow
        key={params.executionId}
        className="border-0"
        nodes={nodes}
        edges={edges}
        maxZoom={1}
        nodeOrigin={[0.5, 0]}
        zoomOnDoubleClick={false}
        fitView
        edgeTypes={{ ExecutionEdge }}
        nodeTypes={nodeTypes}
        proOptions={{ hideAttribution: true }}
      >
        <Controls />
        <Background />
      </ReactFlow>
      <div className="flex w-1/3 max-w-96 flex-col gap-2 overflow-auto border-l bg-white p-4">
        {(data?.workflowExecutions ?? []).map((e) => {
          let startedAt: number | null = null;
          let completedAt: number | null = null;
          e.nodeData.forEach((n) => {
            if (n.startedAt && n.startedAt < (startedAt ?? Date.now()))
              startedAt = n.startedAt;
            if (n.completedAt && n.completedAt > (completedAt ?? 0))
              completedAt = n.completedAt;
          });
          const duration = workflowDuration(
            startedAt ?? undefined,
            completedAt ?? undefined
          );

          return (
            <Link
              key={e.id}
              to={`${activityPath}/${e.id}`}
              relative="path"
              className={cx(
                'flex cursor-pointer items-center gap-3 rounded-md p-2 hover:bg-slate-100',
                e.id === params.executionId ? 'bg-slate-100' : ''
              )}
            >
              <div className="flex-shrink-0">
                <WorkflowStatusIcon status={e.status} />
              </div>
              <FormattedDate value={e.createdAt} />
              <FormattedTime value={e.createdAt} />
              {duration && (
                <div className="ml-auto text-slate-400">{duration}</div>
              )}
            </Link>
          );
        })}
      </div>
    </div>
  );
}
