import { Condition } from './Condition';
import { Confirmation } from './Confirmation';
import { CustomPrompt } from './CustomPrompt';
import { RunMeetingKit } from './RunMeetingKit';
import { SaveToSpace } from './SaveToSpace';
import { Share } from './Share';
import { StartNode } from './StartNode';
import { Template } from './Template';
import { SendData } from './SendData';
import { useIntl } from 'react-intl';

export const nodeTypes = {
  Condition,
  Confirmation,
  CustomPrompt,
  RunMeetingKit,
  SaveToSpace,
  Share,
  StartNode,
  SendData,
  Template,
};

// The node types that can create outputs.
// Stored here so it stays in sync
export const outputNodeTypes = new Set([
  'Confirmation',
  'CustomPrompt',
  'RunMeetingKit',
  'Template',
]);

export const useNodeTypeToName = (): Record<keyof typeof nodeTypes, string> => {
  const intl = useIntl();
  return {
    SaveToSpace: intl.formatMessage({
      defaultMessage: 'Save to a space',
      id: '09nd4p',
    }),
    RunMeetingKit: intl.formatMessage({
      defaultMessage: 'AI: Use a quick prompt',
      id: 'O6OGiL',
    }),
    CustomPrompt: intl.formatMessage({
      defaultMessage: 'AI: Write your own prompt',
      id: 'Sxqlfd',
    }),
    Share: intl.formatMessage({
      defaultMessage: 'Share to an integration',
      id: 'XAznvs',
    }),
    SendData: intl.formatMessage({
      defaultMessage: 'Share to an integration (new)',
      id: 'N5h19h',
    }),
    Confirmation: intl.formatMessage({
      defaultMessage: 'Review before proceeding',
      id: 'Vu4qrQ',
    }),
    Condition: intl.formatMessage({
      defaultMessage: 'Condition',
      id: 'XMpO69',
    }),
    StartNode: intl.formatMessage({
      defaultMessage: 'Start the workflow from a meeting',
      id: '4HXNl1',
    }),
    Template: intl.formatMessage({
      defaultMessage: 'Compose text from template',
      id: 'JiN2vp',
    }),
  };
};
