import React, { useEffect, ReactElement } from 'react';
import { useNavigate } from 'react-router';
import { FormattedMessage } from 'react-intl';

import { useWorkflowDuplicate } from '../../services/Workflow';
import { Tooltip } from '../../components/Tooltip';
import { Copy } from 'lucide-react';

import { Button } from '../../components/buttons';

/**
 * UI for useWorkflowDuplicate hook
 * @param id The id of the workflow to duplicate
 *
 */
export function DuplicateWorkflowButton({
  id,
  variant,
}: {
  id: string;
  variant?: 'icon' | 'secondaryOutline' | 'filled' | undefined;
}): ReactElement {
  const { data, request, loading } = useWorkflowDuplicate(id);
  const isSuccess = Boolean(data && !loading);
  const navigate = useNavigate();
  const newId = data?.duplicateWorkflow.id;

  useEffect(() => {
    if (isSuccess) {
      navigate(`/workflows/${newId}`);
    }
  }, [newId, isSuccess, navigate]);

  return (
    <Tooltip
      arrow
      placement="top"
      isDisabled={variant !== 'icon'}
      title={
        <FormattedMessage defaultMessage="Duplicate workflow" id="IynmOz" />
      }
    >
      <Button
        variant={variant}
        size={variant === 'icon' ? 'small' : undefined}
        onClick={() => request()}
        endIcon={variant === 'icon' ? null : <Copy size="1rem" />}
        loading={loading}
      >
        {variant === 'icon' ? (
          !loading && <Copy size="1rem" />
        ) : (
          <FormattedMessage defaultMessage="Duplicate" id="4fHiNl" />
        )}
      </Button>
    </Tooltip>
  );
}
