import React, { useState, useEffect, ReactElement } from 'react';
import { Controls } from '@xyflow/react';
import { FormattedMessage } from 'react-intl';
import ReactPlayer from 'react-player/youtube';
import { X, CirclePlay } from 'lucide-react';
import { useSelector } from 'react-redux';
import { Button } from '../../components/buttons';
import { ModalDialog } from '../../components/modals';
import {
  trackWorkflowTutorialVideoOpened,
  trackWorkflowsAnnouncementModalViewed,
} from '../../helpers/analytics';
import { RootState } from '../../redux/store';
import { createPortal } from 'react-dom';
import VideoHeroImage from '../../img/workflows-video-hero.png';

const videoTutorialURL = 'https://www.youtube.com/watch?v=CZkJ-c1pJ3M';

export function WatchVideoEditorControl(props: {
  onClose: () => void;
}): ReactElement {
  const uid = useSelector((state: RootState) => state.user.id);
  const [showVideoPlayer, setShowVideoPlayer] = useState(false);
  return (
    <Controls
      position="top-right"
      showFitView={false}
      showInteractive={false}
      showZoom={false}
      className="w-[14em] rounded-card bg-white"
    >
      <div className="rounded-card bg-white p-2">
        <Button
          size="icon"
          variant="icon"
          startIcon={<X className="h-4 w-4 text-slate-400" />}
          onClick={props.onClose}
          className="absolute right-2 top-2"
        />
        <h1 className="bold text-md text-wrap p-2 pt-0 text-center">
          <FormattedMessage
            defaultMessage="Learn how to use workflows"
            id="8BmpIh"
          />
        </h1>
        <div
          className="relative cursor-pointer overflow-hidden rounded-card"
          onClick={() => {
            trackWorkflowTutorialVideoOpened(uid, 'workflow editor');
            setShowVideoPlayer(true);
          }}
        >
          <img src={VideoHeroImage} />
          <div className="absolute bottom-0 left-0 right-0 top-0 bg-gradient-to-t from-slate-600 to-slate-100 opacity-50 hover:opacity-100" />
          <div className="absolute bottom-0 left-0 right-0 top-0 flex items-center justify-center">
            <CirclePlay className="h-12 w-12 text-white transition-all hover:scale-125" />
          </div>
        </div>
      </div>
      <WorkflowTutorialVideoPlayer
        onClose={() => setShowVideoPlayer(false)}
        open={showVideoPlayer}
      />
    </Controls>
  );
}

export function WatchWorkflowIntroVideoButton({
  source,
  onClick,
  onCloseVideo,
  variant = 'secondaryOutline',
}: {
  source: string;
  variant?: 'filled' | 'secondary' | 'secondaryOutline';
  onClick?: () => void;
  onCloseVideo?: () => void;
}): ReactElement {
  const uid = useSelector((state: RootState) => state.user.id);
  const [showVideoPlayer, setShowVideoPlayer] = useState(false);

  const handleClose = () => {
    setShowVideoPlayer(false);
    onCloseVideo && onCloseVideo();
  };
  return (
    <>
      <Button
        variant={variant}
        size="small"
        onClick={() => {
          trackWorkflowTutorialVideoOpened(uid, source);
          setShowVideoPlayer(true);
          onClick && onClick();
        }}
      >
        <FormattedMessage defaultMessage="Watch video" id="wdsniY" />
      </Button>
      <WorkflowTutorialVideoPlayer
        onClose={handleClose}
        open={showVideoPlayer}
      />
    </>
  );
}

// show popup after showAfterMs once for a user
export function WorkflowIntroPopup({
  showAfterMs,
}: {
  showAfterMs: number;
}): ReactElement | null {
  const workflowIntroPopupKey = 'dismissedWorkflowsTutorialAt';
  const [showPopup, setShowPopup] = useState(false);
  const [playing, setPlaying] = useState(false);

  const uid = useSelector((state: RootState) => state.user.id);
  const previouslyClosedOrWatchedWorkflowsVideo = localStorage.getItem(
    workflowIntroPopupKey
  );

  useEffect(() => {
    if (previouslyClosedOrWatchedWorkflowsVideo) {
      return;
    }

    let timer: ReturnType<typeof setTimeout> | null = null;
    function scheduleShowPopup() {
      timer = setTimeout(() => {
        const otherModalsVisible = document
          .getElementById('app')
          ?.hasAttribute('aria-hidden');
        if (otherModalsVisible) {
          scheduleShowPopup();
          return;
        }
        trackWorkflowsAnnouncementModalViewed(uid);
        setShowPopup(true);
      }, showAfterMs);
    }
    scheduleShowPopup();
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [showAfterMs, uid, previouslyClosedOrWatchedWorkflowsVideo]);

  if (previouslyClosedOrWatchedWorkflowsVideo) {
    return null;
  }

  const rememberPopupDismiss = () => {
    const timestamp = Date.now();
    localStorage.setItem(workflowIntroPopupKey, timestamp.toString());
  };

  const handleClose = () => {
    setShowPopup(false);
    rememberPopupDismiss();
  };

  const handlePlayClicked = () => {
    if (!playing) {
      trackWorkflowTutorialVideoOpened(uid, 'announcement modal');
    }
  };

  const onPause = () => {
    setPlaying(false);
  };

  return (
    <>
      <ModalDialog
        open={showPopup}
        onClose={handleClose}
        size="regular"
        actions={
          <div className="flex w-full flex-row justify-center gap-4">
            <Button onClick={handleClose} variant="light">
              <FormattedMessage defaultMessage="Close" id="rbrahO" />
            </Button>
            <Button
              onClick={() => {
                setPlaying(true);
                handlePlayClicked();
              }}
            >
              <FormattedMessage defaultMessage="Watch video" id="wdsniY" />
            </Button>
          </div>
        }
      >
        <div>
          <div className="overflow-hidden rounded-dialog">
            <ReactPlayer
              url={videoTutorialURL}
              controls
              width="100%"
              onPlay={handlePlayClicked}
              onPause={onPause}
              playing={playing}
              pip={true}
            />
          </div>
          <h1 className="p-4 text-center text-2xl">
            <FormattedMessage
              defaultMessage="Introducing Workflows"
              id="zCgyxY"
            />
          </h1>
          <p className="text-center text-slate-500">
            <FormattedMessage
              defaultMessage="Automate your post meeting tasks and knowledge extraction with workflows."
              id="0WdoP8"
            />
          </p>
        </div>
      </ModalDialog>
    </>
  );
}

export function WorkflowTutorialVideoPlayer({
  onClose,
  open,
}: {
  onClose: () => void;
  open: boolean;
}): ReactElement | null {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        onClose();
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    document.getElementById('app')?.setAttribute('aria-hidden', 'true');

    const handleResize = () => {
      setScreenWidth(window.innerWidth);
      setScreenHeight(window.innerHeight);
    };
    window.addEventListener('resize', handleResize);

    return () => {
      document.getElementById('app')?.removeAttribute('aria-hidden');
      document.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('resize', handleResize);
    };
  }, [onClose]);

  if (!open) {
    return null;
  }

  const maxWidth = 1024;
  const maxHeight = 576;
  let playerWidth = screenWidth;
  let playerHeight = screenHeight;
  if (playerWidth > maxWidth) {
    playerWidth = maxWidth;
  }
  if (playerHeight > maxHeight) {
    playerHeight = maxHeight;
  }
  if (playerWidth / playerHeight > 16 / 9) {
    playerWidth = playerHeight * (16 / 9);
  }
  if (playerWidth / playerHeight < 16 / 9) {
    playerHeight = playerWidth * (9 / 16);
  }

  return createPortal(
    <div
      className="fixed left-0 top-0 z-Backdrop flex h-screen w-screen flex-row items-center justify-center bg-slate-900 bg-opacity-80"
      onClick={onClose}
    >
      <div className="absolute right-8 top-8">
        <X className="h-8 w-8 cursor-pointer text-white" onClick={onClose} />
      </div>

      <div className="overflow-hidden rounded-dialog">
        <ReactPlayer
          url={videoTutorialURL}
          controls
          width={playerWidth}
          height={playerHeight}
          className="rounded-dialog"
        />
      </div>
    </div>,
    document.body
  );
}
