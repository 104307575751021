import React, { ReactElement, useState } from 'react';
import { useReactFlow } from '@xyflow/react';
import { SingleSourceNode, WorkflowNodeProps } from './BaseNode';
import { Select } from '../../../components/Select';
import {
  ArrowRightLeft,
  Ellipsis,
  Mail,
  Share2 as ShareIcon,
} from 'lucide-react';
import logo from '../../../img/tactic-logomark.svg';
import { ConfigUiOverrides, JsonFormOverrides } from '../JsonFormOverrides';
import {
  useDataSource,
  useDataSourceConfigure,
  useConnectionCreate,
  useConnectionList,
  useConnectionDisconnect,
  useIntegrationGet,
  DataSchema,
  useDataCollectionList,
  useDataSourceUpdate,
} from '../../../services/Integration';
import { Button } from '../../../components/buttons';
import { useWorkflowId } from '../WorkflowIdContext';
import { Chip } from '../../../components/Chips';
import { Menu } from '../../../components/Menu';
import { Spinner } from '../../../components/Spinner';
import { FormattedMessage } from 'react-intl';
import { enqueueSnackbar } from 'notistack';
import { JsonForm, UiSchemaItem, UiSchema } from '../JsonForm';
import { LiquidTemplateInput } from '../../../components/LiquidTemplateInput';
import { useAutocomplete } from './useAutocomplete';
import { useIsPreview } from './useIsPreview';

export function SendData(
  props: WorkflowNodeProps<{
    type: string;
    fieldData: Record<string, unknown>;
    email?: {
      subject: string;
      message: string;
    };
  }>
): ReactElement {
  const { id, data } = props;
  const { type, fieldData = {} } = data;
  const { updateNodeData } = useReactFlow();
  const { workflowId } = useWorkflowId();
  const isPreview = useIsPreview();
  const instanceKey = `${workflowId}::${id}`;

  const optionsData = useConnectionList();
  const disconnect = useConnectionDisconnect(type);
  const options = optionsData.data?.items ?? [];

  const isExecution = Boolean(props.data.execution);

  const connections = new Map(options.map((opt) => [opt.key, opt.connection]));
  const connection = connections.get(type);

  const isConnected =
    connection && !connection.error && !connection.disconnected;

  const dataSource = useDataSource(
    isConnected ? { type, instanceKey } : undefined
  );
  const configure = useDataSourceConfigure({ type, instanceKey });

  const { collectionKey, collectionParameters, collectionSpec } =
    dataSource.data ?? {};
  const { fieldsSchema, parametersSchema } = collectionSpec ?? {};

  return (
    <SingleSourceNode
      minHeight={132}
      workflowNode={props}
      icon={
        <ShareIcon className="size-8 rounded-md bg-brand-50 p-2 text-brand" />
      }
      contentClassName={!isConnected ? 'h-full' : ''}
    >
      {optionsData.isLoading ? (
        <Spinner className="m-auto" />
      ) : (
        <div className="flex flex-grow flex-col gap-3">
          <div className="nodrag flex items-center gap-2">
            <Select
              value={type}
              disabled={isExecution}
              onChange={(type) => updateNodeData(id, { type, fieldData: {} })}
              full
              options={[
                ...options.map((ii) => ({
                  value: ii.key,
                  label: ii.name,
                  icon: (
                    <div className="relative">
                      {connections.get(ii.key) && (
                        <Chip
                          className="absolute -left-1 -top-1"
                          type="dot"
                          color={
                            connections.get(ii.key)?.error ? 'red' : 'green'
                          }
                        />
                      )}
                      <img
                        src={ii.logoUri}
                        height={16}
                        width={16}
                        className="overflow-hidden rounded-sm"
                      />
                    </div>
                  ),
                })),
                {
                  value: 'email',
                  label: 'Email',
                  icon: <Mail size="1rem" />,
                },
              ]}
            />
            {connections && !isExecution && (
              <Menu>
                <Menu.Trigger>
                  <Button variant="naked">
                    {disconnect.isMutating ? (
                      <Spinner />
                    ) : (
                      <Ellipsis size="1rem" />
                    )}
                  </Button>
                </Menu.Trigger>
                <Menu.Item onClick={() => configure.trigger()}>
                  Configure
                </Menu.Item>
                <Menu.Item onClick={() => disconnect.trigger()}>
                  Disconnect
                </Menu.Item>
              </Menu>
            )}
          </div>
          {type === 'email' ? (
            <EmailSelect
              node={props}
              value={data.email}
              workflowId={workflowId}
              disabled={isExecution || isPreview}
              onChange={(next) =>
                updateNodeData(id, {
                  [type]: { ...next },
                })
              }
            />
          ) : (
            <>
              {type && !isConnected && (
                <ConnectionForm key={type} type={type} />
              )}
              {parametersSchema && (
                <ConfigForm
                  schema={parametersSchema}
                  uiSchema={ConfigUiOverrides[type]}
                  type={type}
                  instanceKey={instanceKey}
                  collectionKey={collectionKey}
                  value={collectionParameters}
                  disabled={isExecution}
                />
              )}
              {fieldsSchema && (
                <JsonForm
                  schema={fieldsSchema}
                  value={fieldData}
                  disabled={isExecution}
                  uiSchema={JsonFormOverrides[type]}
                  onChange={(next) => updateNodeData(id, { fieldData: next })}
                />
              )}
            </>
          )}
        </div>
      )}
    </SingleSourceNode>
  );
}

function ConnectionForm(props: { type: string }) {
  const { type } = props;
  const integration = useIntegrationGet(type);
  const [value, onChange] = useState<unknown>({});
  const connect = useConnectionCreate(type, value);

  if (integration.isLoading) return <Spinner className="m-auto" />;

  if (!integration.data) return null;
  const { name, authOptions, logoUri } = integration.data;

  return (
    <div className="tq-dashed-border flex flex-grow flex-col items-center justify-center gap-4 p-6 px-10">
      <div className="flex items-center justify-center gap-3">
        <img className="h-12 w-12" src={logo} alt="Tactiq Logo" />
        <ArrowRightLeft className="size-6 stroke-slate-800" />
        <img
          src={logoUri}
          height={48}
          width={48}
          className="overflow-hidden rounded-lg"
        />
      </div>
      <div className="text-center text-lg font-bold">
        <FormattedMessage
          defaultMessage="Connect Tactiq to {name}"
          id="4YYBtp"
          values={{ name }}
        />
      </div>
      {authOptions?.map((item) => {
        // integration app's types here are not correct. They think
        // ui does not exist even though it clearly does.
        const auth = item as { key: string; ui?: { schema: DataSchema } };
        return (
          <React.Fragment key={auth.key}>
            <JsonForm
              disabled={false}
              schema={auth.ui?.schema ?? { type: 'object' }}
              value={value}
              onChange={onChange}
            />
            <Button
              variant="secondaryOutline"
              loading={connect.isMutating}
              onClick={async () => {
                try {
                  await connect.trigger(auth.key);
                } catch (e) {
                  enqueueSnackbar(e.message, {
                    variant: 'ERROR',
                    anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
                  });
                }
              }}
            >
              <FormattedMessage defaultMessage="Allow access" id="YXzlhm" />
            </Button>
          </React.Fragment>
        );
      })}
    </div>
  );
}

const ConfigForm: React.FC<{
  type: string;
  instanceKey: string;
  schema: DataSchema;
  disabled: boolean;
  value?: Record<string, string>;
  collectionKey?: string;
  uiSchema?: UiSchema;
}> = (props) => {
  const { type, value, instanceKey, collectionKey, schema, uiSchema } = props;

  if (!uiSchema) return null;
  if (!collectionKey) return null;

  return (
    <div className="nodrag">
      {uiSchema?.map((itemSchema) => {
        const referenceCollectionKey =
          schema.properties?.[itemSchema?.path]?.referenceCollection?.key;

        return referenceCollectionKey ? (
          <CollectionList
            key={itemSchema.path}
            disabled={props.disabled}
            type={type}
            instanceKey={instanceKey}
            schema={itemSchema}
            collectionKey={collectionKey}
            referenceCollectionKey={referenceCollectionKey}
            value={value?.[itemSchema.path]}
          />
        ) : null;
      })}
    </div>
  );
};

const CollectionList: React.FC<{
  type: string;
  instanceKey: string;
  schema: UiSchemaItem;
  collectionKey: string;
  referenceCollectionKey: string;
  value?: string;
  disabled: boolean;
}> = (props) => {
  const {
    type,
    instanceKey,
    schema,
    collectionKey,
    referenceCollectionKey,
    value,
    disabled,
  } = props;
  const list = useDataCollectionList({
    type,
    collectionKey: referenceCollectionKey,
  });
  const update = useDataSourceUpdate({
    type,
    collectionKey,
    instanceKey,
  });

  const options =
    list.data?.records.map((ii) => ({
      value: ii.id,
      label: ii.name ?? ii.id,
    })) ?? [];

  return (
    <>
      <div className="mb-2 w-full min-w-32 text-sm font-medium">
        {schema.label?.()}
      </div>

      <Select
        loading={list.isLoading}
        disabled={list.isLoading || disabled}
        full
        value={value}
        options={options}
        onChange={(next) => {
          if (next) {
            update.trigger({
              [schema.path]: next,
            });
          }
        }}
      />
    </>
  );
};

/**
 * Send email is a legacy node type that integration.app does not provide.
 * So it's patched in to the ui, and is handled separately in the api.
 */
const EmailSelect: React.FC<{
  value?: { subject: string; message: string };
  onChange: (options: { subject: string; message: string }) => void;
  node: WorkflowNodeProps<unknown>;
  disabled: boolean;
  workflowId: string;
}> = (props) => {
  const { onChange, value, disabled, workflowId } = props;
  const autocomplete = useAutocomplete(props.node);
  const { message = '', subject = '' } = value ?? {};

  return (
    <div className="flex flex-grow flex-col">
      <label className="block text-sm font-semibold leading-6 text-slate-600">
        <FormattedMessage defaultMessage="Subject" id="LLtKhp" />
      </label>
      <LiquidTemplateInput
        className="mb-3 min-h-14 text-base"
        properties={autocomplete.properties}
        variables={autocomplete.variables}
        nodeType="SendData"
        disabled={disabled}
        workflowId={workflowId}
        value={subject}
        onChange={(next) => onChange({ message, subject: next })}
      />
      <label className="block text-sm font-semibold leading-6 text-slate-600">
        <FormattedMessage defaultMessage="Message" id="T7Ry38" />
      </label>
      <LiquidTemplateInput
        className="min-h-14 basis-1/2 text-base"
        properties={autocomplete.properties}
        variables={autocomplete.variables}
        nodeType="SendData"
        disabled={disabled}
        workflowId={workflowId}
        value={value?.message ?? ''}
        onChange={(next) => onChange({ subject, message: next })}
      />
    </div>
  );
};
