import React, { ReactElement } from 'react';
import { SingleSourceNode, WorkflowNodeProps } from './BaseNode';
import { Zap } from 'lucide-react';

export function StartNode(props: WorkflowNodeProps): ReactElement {
  return (
    <SingleSourceNode
      icon={<Zap className="size-8 rounded-md bg-brand-50 p-2 text-brand" />}
      workflowNode={props}
      hideId
      noResize
      minHeight={60}
    />
  );
}
